@import '../layouts/user/assets/css/style.css';
@import 'animate.css';

.loader {
  display: inline-block;
  /* margin-top: 70px; */
  width: 38px;
  height: 38px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
}

.loader::before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #07bc0c;
  animation: prixClipFix 2s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }

  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }

  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }

  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }

  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

.Login .MuiTextField-root {
  background-color: #e8f0fe !important;
}

.css-11ea04f-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: red;
}

.css-6d3pg0-MuiTabs-indicator {
  background-color: #70ad47;
}

p {
  margin-bottom: 0 !important;
}

.custom-btn {
  color: white;
  /* background: #4abd43; */
  background: #1f9e6d;
}

.custom-btn:hover {
  color: white;
  /* background: #4abd43; */
  background: #1f9e6d;
}

.css-e5dh6b .MuiDataGrid-columnHeaders {
  background-color: #f5f5f5 !important;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
}

.css-e5dh6b .MuiDataGrid-footerContainer {
  background-color: #f5f5f5 !important;
}

.tabImgZoomIn {
  transition: transform 0.2s;
}

.tabImgZoomIn:hover {
  transform: scale(1.2);
}

.tabImgWhite {
  filter: invert(100%) grayscale(100%) brightness(3);
}

.tab-text,
.tab-text:focus {
  color: white !important;
  font-size: 17px;
}

.table-custom-inpt-field {
  color: white;
}

/* .table-custom-inpt-field .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  -webkit-text-fill-color: #fff;
} */

/* .table-custom-inpt-field .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input {
  border: 1px solid white !important;
  border-radius: 6px;
} */

.table-custom-inpt-field .custom-dateTimePicker-field {
  color: white !important;
  border: 1px solid white !important;
  border-radius: 6px;
}

.date-time-color>div>div {
  color: white !important;
}

.date-time-color svg {
  color: white !important;
}

/* .custom-dateTimePicker-field .css-e9crry-MuiInputBase-input-MuiOutlinedInput-input {
  opacity: 1;
  -webkit-text-fill-color: #ffffff !important;
  padding: 9px 14px;
}

.custom-dateTimePicker-field .css-e9crry-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: #ffffff !important;
} */

.custom-dateTimePicker-field input {
  color: #fff;
  -webkit-text-fill-color: #fff ;
  padding: 9px 14px;
}

.custom-dateTimePicker-field>div svg {
  color: #ffffff !important;
}

.custom-dateTimePicker-field>div {
  color: #ffffff !important;
  border: 1px solid white;
}

.custom-dateTimePicker-field .Mui-disabled {
  color: #ffffff !important;
  -webkit-text-fill-color: #ffffff !important;
}

.custom-dateTimePicker-fieldError>div {
  border: 1px solid #ff0000 !important;
}

/* .custom-dateTimePicker-field>div .Mui-disabled {
  color: #ffffff !important;
  border: 1px solid white;
} */

.custom-dateTimePicker-field>div .Mui-disabled svg {
  color: #ffffff !important;
}

.custom-placeholder::placeholder {
  color: white;
}

.custom-inpt-field-color input {
  color: white !important;
  -webkit-text-fill-color: white !important;
}

/* .table-custom-inpt-field .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input:hover,
.table-custom-inpt-field .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input:focus {
  border-color: white !important;
} */

/* .table-custom-inpt-field .css-1l5g511-MuiInputBase-root-MuiOutlinedInput-root {
  border: 1px solid red !important;
} */

.custom-inner-bg {
  background: #054723;
}

.custom-outer-bg {
  /* background: #1f9e6d; */
}

.label-white {
  color: white;
}

.tab-outer .css-heg063-MuiTabs-flexContainer {
  display: flex;
  justify-content: center;
}

.table-custom-inpt-field .css-1c5w2no-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-1c5w2no-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-1c5w2no-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  border: 1px solid white !important;
  border-radius: 6px;
  color: white !important;
}

.table-custom-inpt-field .css-wb57ya-MuiFormControl-root-MuiTextField-root {
  border: 1px solid white !important;
  border-radius: 6px;
  color: white !important;
}

.table-custom-inpt-field .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-u85066-MuiAutocomplete-root .MuiOutlinedInput-root {
  color: white;
}

.template-inner-bg {
  background: #054723;
}

.template-outer-theme {
  background: white !important;
  color: #1f9e6d !important;
}

.btn-contact{
  text-decoration: none;
  background-color: #054723;
  padding: 10px 20px;
  border-radius: 5px;
}
.btn-contact:hover{
  background-color: #07341b;
}

/* .template-outer-theme-2 {
  background: white !important;
  color: #1f9e6d !important;
} */

.template-inner-theme {
  background: #054723 !important;
  color: white;
}

/* .template-inner-theme-2 {
  background: #054723 !important;
  color: white;
} */

.slick-arrow.slick-prev,
.slick-arrow.slick-next {
  background-color: #054723;
  border-radius: 50%;
}

.calendly-inline-widget {
  height: 73vh !important;
}

.bot .MuiDialog-paper {
  position: absolute !important;
  width: 420px;
  bottom: 0;
  right: 0;
  width: 300px;
}

.bot .css-ypiqx9-MuiDialogContent-root {
  padding: 20px 24px 0px 20px !important;
}

/* .table-custom-inpt-field .css-u85066-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
  color: white
} */

.tab-fixed .MuiTabs-scroller {
  display: flex;
  justify-content: center;
}

.tab-text-color {
  color: #1f9e6d !important;
}

.textborder input {
  border: 1px solid white;
  border-radius: 6px;
}

.textErrorborder input {
  border: 1px solid #ff0000;
  border-radius: 6px;
}

.textborder svg {
  color: white !important;
}

.apexcharts-legend-text,
tspan,
title {
  color: #fff !important;
}

text {
  fill: #fff !important;
}

.apexcharts-menu {
  background-color: #1f9e6d !important;
}

.apexcharts-theme-light .apexcharts-menu-item:hover {
  background: #054723 !important;
}

.apexcharts-tooltip .apexcharts-active {
  color: #054723 !important;
}

/* .apexcharts-tooltip .apexcharts-tooltip-marker {
  background-color: #054723 !important;
} */

.box-with-border {
  position: relative;
  padding-right: 5px;
}

.box-with-border::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 1px;
  height: 75%;
  background-color: #054723;
  margin: auto;
}

.credit {
  background: url('../layouts/user/assets/images/buyCredit.png');
  height: 450px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: 91% 28%;
  /* background-position: center; */
  background-size: cover;
  position: relative;
  z-index: 99;
}

.strong-ol {
  color: #000;
  margin-top: 15px;
}

.strong-ol li::marker {
  color: #000;
  font-weight: bold;
}

.strong-ol li {
  color: #000;
  font-weight: normal;
}

.box-title-padding {
  padding-bottom: 20px;
}

.rs-picker-popup-daterange{
  z-index: 99999999 !important;
}